import { Component, Inject, HostListener, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  show_general_issue_message = false;
  general_issue_message = "We're experiencing technical difficulties and are working on a solution. User experience may be affected.";
  backgroundColor: string;
  public theme = {
    'primary': '#111111',
    'secondary': '#5d5a5a'
  }


  constructor(
    private _elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private router: Router,
  ) {
    document.body.style.setProperty('--bs-info', this.theme.primary);
    document.body.style.setProperty('--bs-link-color', this.theme.primary);

    this.loadDynamicConfig();

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationStart) {
    //     debugger
    //     console.log('Redirect event:', event.url);
    //   }
    // });

    this._elementRef.nativeElement.removeAttribute("ng-version");
  }

  @HostListener('window:resize')
  calculateScalingFactor() {
    const screenWidth = window.innerWidth;
    let factor = 0.00013 * screenWidth + 0.7499;
    this.document.documentElement.style.setProperty('--scaling-factor', String(factor));
  }

  loadDynamicConfig() {
    this.http.get('https://beta-logos.s3.eu-central-1.amazonaws.com/conf/product.config.json', {
      headers: new HttpHeaders({
        'Accept': 'application/json'  // Requesting JSON data
      })
    }).subscribe({
      next: (data: any) => {
        this.show_general_issue_message = data?.show_general_issue_message;
        this.general_issue_message = data?.general_issue_message || this.general_issue_message;
        // this.consent_service.ob_provider = data?.ob_provider;
      },
      error: (error) => {
        console.error('Error fetching config:', error);
      }
    });
  }
}
